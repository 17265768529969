$brand-primary:         #353535;
$brand-secondary:       #0ca13e;
$brand-accent:          #FFE900;

$navbar-inverse-bg:   $brand-primary;
$navbar-inverse-link-active-color: #fff !default;

$btn-secondary-color:        #fff !default;
$btn-secondary-bg:           $brand-secondary !default;
$btn-secondary-border:       darken($btn-secondary-bg, 5%) !default;

$btn-accent-color:        #222 !default;
$btn-accent-bg:           $brand-accent !default;
$btn-accent-border:       darken($btn-accent-bg, 5%) !default;


@import "main";